<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addTax'})">الضرايب</dashboard-page-title>

    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK" :fields="fields" list_url="admin/taxes"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'الاسم', key: 'id', class: 'text-right' },
        { label: 'الاسم', key: 'title', class: 'text-right' },
        { label: 'الرقم البريدي', key: 'post_code', class: 'text-right' },
        { label: 'القيمة', key: 'percentage', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: true,
              routePath: 'edit-tax/:id'
            },
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'admin/taxes',
              baseUrl: VUE_APP_MARKETPLACE_LINK,
              titleHeader: 'ضريبة',
              question: '؟هل متأكد أنك تريد مسح هذه الضريبة',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد, مسح',
              textCancelButton: 'إلغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
